<template>
  <div class="register">
    <div class="container-fluid" style="background-color:rgba(0,0,0,0.5);min-height:100vh;">
      <div class="row">
        <!-- login block -->
        <div class="col-md-4 offset-md-4 m-pad-0">
          <div class="form-block" style="margin-top:20vh;">
            <div class="pad-bottom-5 text-center">
              <a href="/"><img src="/static/aacharya.png" style="width:30%;" alt=""></a>
            </div>
            <template v-if="login_failed_error">
              <div style="display:block;text-align:center;padding:10px;color:red;font-size: 16px;">{{login_failed_error}}</div>
            </template>
            <div class="form-group">
              <label for="exampleInputEmail1">Email or Mobile Number <span class="req">*</span></label>
              <input type="email" v-on:keyup.enter="login()" name="email" v-model="email" class="aa-form-control"
              :disabled="processing||step===2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email or Mobile Number">
              <span class="error" v-if="login_error && login_error.email">{{login_error.email}}</span>
            </div>
            <template v-if="step===1">
              <div class="form-group margin-bottom-0">
                <label for="exampleInputPassword1">Password <span class="req">*</span></label>
                <input type="password" v-on:keyup.enter="login()" name="password" v-model="password" class="aa-form-control"
                :disabled="processing" id="exampleInputPassword1" placeholder="Password">
                <span class="error" v-if="login_error && login_error.password">{{login_error.password}}</span>
              </div>
              <button type="submit" :disabled="processing" class="aa-btn btn-success margin-top-10 margin-bottom-10" @click="login()">
                <span v-if="!processing"><span class="fa fa-lock"></span> Secure Login</span>
                <span v-if="processing"> Processing....</span>
                </button><br>
            </template>
            <template v-if="step===2">
              <div class="form-group margin-bottom-0">
                <label for="exampleInputPassword1">OTP <span class="req">*</span></label>
                <input type="number" v-on:keyup.enter="validateOTP()" name="password" v-model="otp" class="aa-form-control"
                :disabled="processing" id="exampleInputPassword1" placeholder="Enter OTP">
                <span class="error" v-if="login_error && login_error.otp">{{login_error.otp}}</span>
              </div>
              <button type="submit" :disabled="processing" class="aa-btn btn-success margin-top-10 margin-bottom-10" @click="validateOTP()">
                <span v-if="!processing"><span class="fa fa-lock"></span> Verify</span>
                <span v-if="processing"> Processing....</span>
                </button><br>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      profile: {},
      error: '',
      email: '',
      password: '',
      otp: null,
      login_error: null,
      saving_data: false,
      login_failed_error: null,
      processing: false,
      step: 1
    }
  },
  created () {
  },
  methods: {
    login() {
      localStorage.setItem('APP_VERSION', 1);
      const that = this;
      if(that.validateLogin()) {
        that.processing = true;
        that.saving_data = true
        that.$cs.auth.login(that.email, that.password)
        .then(res => {
          if(res.data.user.user_type.toLowerCase() === 'superuser') {
            // this.step = 2;
             localStorage.setItem('Token',res.data.token);
            localStorage.setItem('user_details',JSON.stringify(res.data.user));
            localStorage.setItem('APP_VERSION', 1);
            that.$router.push('/');
            that.processing = false
          } else {
            this.login_failed_error = "Invalid login credentials..";
          }
        }, err => {
          that.processing = false
          const error = err.response;
          this.login_failed_error = "Invalid login credentials.."
          if (error.status === 400) {
          }
        })
        that.saving_data = false
      }
    },
    validateOTP(){
      const that = this;
      that.processing = true;
      that.saving_data = true
      that.$cs.auth.verify({
          mobileOrEmail: that.email,
          otp: that.otp
        })
      .then(res => {
        localStorage.setItem('Token',res.data.token);
        localStorage.setItem('user_details',JSON.stringify(res.data.user));
        localStorage.setItem('APP_VERSION', 1);
        that.$router.push('/');
      }, err => {
        that.processing = false
        const error = err.response;
        this.login_failed_error = "Invalid login credentials.."
        if (error.status === 400) {
        }
      })
      that.saving_data = false
    },
    validateLogin() {
      const that = this;
      that.login_failed_error = '';
      that.login_error = {};
      const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var proceed = true;
      // if (that.email === null || that.email === "") {
      //   this.login_error.email = "Email field should not be null";
      //   proceed = false;
      // }
      if (that.email === null || that.email === "") {
        this.login_error.email = "Number field should not be null";
        proceed = false;
      }
      if (that.password === null || that.password === "") {
        this.login_error.password = "Password field should not be null";
        proceed = false;
      }
      // if (!isValidEmail.test(String(this.email).toLowerCase())) {
      //   this.login_error.email = "Enter a valid email address";
      //   proceed = false;
      // }
      return proceed;
    }
  }
};
</script>
<style lang="stylus" scoped>
#partitioned
  padding-left: 10px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  outline none
.form-block
  // min-height 80vh
.auth-links
  list-style none
  margin 0px 0px 20px
  padding 0px
  width 100%
  li
    width 50%
    padding 10px
    float left
    text-align center
    border-bottom 2px solid #ddd
    cursor pointer
    font-weight bold
    &.active
      background-color green
      color white
      border-bottom 2px solid green
.register {
  background url('https://images.unsplash.com/photo-1484415063229-3d6335668531?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1088&q=80');
  background-attachment: fixed;
  background-size: 100% 100%;
  height: 100vh;
  overflow-y: scroll;
}
.plans-list
  list-style none
  margin 0px
  padding 0px
  li
    border 1px solid #ddd
    border-radius 3px
    margin 10px 0px
    cursor pointer
    &:hover, &.active
      border-color #5cb85c
    .plan-body
        padding 5px 10px 10px
        position relative
        .fa-check-circle
          position absolute
          top 10px
          right 10px
          color #5cb85c
        h3
          margin-top 0px
          font-weight bold
        .price-tag
            color green
            font-weight bold
        .validity-tag
            color orangered
            font-weight bold

.brand-block {
  background-color: white;
  padding: 50px 30px;
}
.appbrand-block {
  background-color: white;
  padding: 30px;
}
.form-block {
  background-color: rgba(255, 255, 255, 1);
  padding: 40px 20px 60px;
  // border-top 2px solid red
  // border-radius 0px 20px 0px 0px
  margin-bottom 10vh
}
.req {
  color: red;
}
.form-group label {
  font-weight: bold;
}
.radio label {
  font-weight: 400;
}
.footer-nav {
  float: right;

  li {
    margin: 5px 5px 5px 10px;

    a {
      color: #aaa;
    }
  }
}

.footer-social-nav {
  float: right;

  li {
    margin: 5px;
  }
}
@media (max-width: 767px)
  .appbrand-block
    padding 20px 10px 0px
    text-align center
    .app-logo
      height 60px
  .form-block
    margin 0px
    min-height 100vh
    padding 20px 30px
    h3
      text-align center
      font-weight bold
.select-block
  margin-top 20px
  padding 10px
  border 1px solid #eee
  font-weight bold
</style>